import React, { useEffect, } from "react"
import Layout from '../components/layout'
import { Link } from "gatsby"
import Logo from "../images/eidosstudio.svg"
import {gsap} from 'gsap'

export default function Index() {


  useEffect(() => {    
    const tl = gsap.timeline({ repeat:-1, yoyo:true });
    tl.fromTo(".graphic", {opacity:0, transformOrigin:"center center" },{ keyframes: [
        { duration: 15, backgroundSize: "230%"}, 
      ]});  
}, []);


  return (


      <Layout>
               
    

        <div className="ph0 w-100" style={{ marginTop: '-5rem' }}>
              <div className="w-100 center bg-black cf">

                <div className="w-100 min-vh-100 center bg-center graphic" style={{ backgroundImage: `url(${Logo})`, backgroundSize: '400%' }}>
                    <div className="min-vh-100 flex items-center justify-center">

       

                      <div className="ph2 mw-600 animate-03 fadeInSubtle">

                          <div>
                            <div className="f1 fw9 gray-50 ">Eidos</div> 
                            <div className="f2 fw1 gray-100">Studio</div>
                          </div>

                          <div className="w-100 f6 ls-xsm gray-50 fw3 pv2">A full stack digital agency specialising in creative direction and ideation, bespoke publishing, graphic design, front and back-end software engineering. </div>
                          
                          <Link  className="db mb1" to={`/about`} >
                            <span className="link ls-xsm br-1 bg-gray-50 black-900  ph2 pv05 f6 pointer tc center dib hover-bg-white-50">
                            What we do?
                            </span>
                          </Link>

                          <Link className="db mb1"  to={`/custom-publish`} >
                            <span className="link ls-xsm br-1 bg-gray-50 black-900  ph2 pv05 f6 pointer tl center dib hover-bg-white-50">
                            A case study in Modern Publishing
                            </span>
                          </Link>

                          <Link className="db mb1"  to={`/menu-page`} >
                            <span className="link ls-xsm br-1 bg-gray-50 black-900  ph2 pv05 f6 pointer tc center dib hover-bg-white-50">
                            Portfolio
                            </span>
                          </Link>



                      </div>

                

                    </div>
                </div>
            </div>
            </div>
           
       
         
 
       
      </Layout>

    
  )
}
